import React from 'react';
import Landing from 'components/pages/Landing';
import { fetchGlobals, fetchPageLanding } from 'services/contentful';

const LandingPage = props => <Landing {...props} />;

export async function getStaticProps() {
    const page = await fetchPageLanding();
    const globals = await fetchGlobals();

    return {
        props: { ...page, ...globals },
        revalidate: 60,
    };
}

export default LandingPage;
