import React from 'react';
import PropTypes from 'prop-types';
import Img from 'components/ui/Img';

const Poster = ({ image, videos }) => {
    if (typeof videos !== 'undefined') {
        return (
            <video
                width="100%"
                poster={`${image.url}?fm=webp&q=80`}
                playsInline
                muted
                autoPlay
                loop
            >
                {videos.map((video, index) => (
                    <source
                        key={index}
                        src={video.url}
                        type={video.contentType}
                    />
                ))}
            </video>
        );
    }

    if (image) {
        return (
            <Img
                src={image.url}
                width={image.width}
                height={image.height}
                sizes={`100vw`}
                alt={image.title}
            />
        );
    }

    return null;
};

Poster.propTypes = {
    image: PropTypes.object,
    videos: PropTypes.array,
};

export default Poster;
