import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/ui/Button';
import SectionHeader from 'components/ui/SectionHeader';
import VimeoPlayer from 'components/ui/VimeoPlayer';
import Popcorn from './Popcorn';
import styles from './About.module.scss';

const LandingAbout = props => {
    return (
        <section className={styles.root}>
            <div className={styles.sectionContainer}>
                <SectionHeader {...props} />
            </div>
            <Popcorn />
            <VimeoPlayer {...props.video} />
            <div className={styles.ctaContainer}>
                <Button className={styles.calltoaction} href="/about">
                    See what we can do
                </Button>
            </div>
        </section>
    );
};

LandingAbout.propTypes = {
    headline: PropTypes.object.isRequired,
    video: PropTypes.shape({
        id: PropTypes.string,
        poster: PropTypes.object,
    }).isRequired,
};

export default LandingAbout;
