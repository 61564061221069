import React from 'react';
import PropTypes from 'prop-types';
import Carousel from '@madeinhaus/carousel';
import Img from 'components/ui/Img';
import Text from 'components/ui/Text';
import { breakpoints } from 'utils';
import styles from './ClientsCarousel.module.scss';

const ClientsCarousel = ({ clients }) => {
    const perRow = clients.length / 3;

    const splitClients = clients.reduce((all, one, i) => {
        const ch = Math.floor(i / perRow);
        all[ch] = [].concat(all[ch] || [], one);
        return all;
    }, []);

    const parseSplitClients = [
        [...splitClients[0], ...splitClients[0]],
        [...splitClients[1], ...splitClients[1]],
        [...splitClients[2], ...splitClients[2]],
    ];

    return (
        <div className={styles.root}>
            {parseSplitClients.map((clientRow, i) => (
                <Carousel key={i} className={styles.carousel}>
                    {clientRow.map((client, i) => {
                        const logo = client?.logo || client;

                        return (
                            <div key={i} className={styles.logo}>
                                {(logo?.url && (
                                    <Img
                                        alt={logo.title}
                                        src={logo.url}
                                        width={logo.width}
                                        height={logo.height}
                                        sizes={`(max-width: ${breakpoints.medium}px) 60px, 150px`}
                                    />
                                )) || <Text theme="label">{client.title}</Text>}
                            </div>
                        );
                    })}
                </Carousel>
            ))}
        </div>
    );
};

ClientsCarousel.propTypes = {
    clients: PropTypes.array,
};

export default ClientsCarousel;
