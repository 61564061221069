import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import ClientsCarousel from 'components/ui/ClientsCarousel';
import Hero from './Hero';
import Work from './Work';
import About from './About';
import styles from './Landing.module.scss';

const Landing = ({
    heroHeadline,
    vennDiagramVideos,
    aboutHeadline,
    aboutVideoId,
    aboutVideoPosterImage,
    aboutVideoPosterVideos,
    workHeadline,
    workCaseStudies,
    clients,
    metaTitle,
    metaDescription,
}) => {
    return (
        <div className={styles.root}>
            <Head>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
            </Head>
            <Hero
                headline={heroHeadline.json}
                vennDiagram={vennDiagramVideos}
            />
            <About
                headline={aboutHeadline.json}
                video={{
                    id: aboutVideoId,
                    poster: {
                        image: aboutVideoPosterImage,
                        videos: aboutVideoPosterVideos,
                    },
                }}
            />
            <Work headline={workHeadline.json} caseStudies={workCaseStudies} />
            <ClientsCarousel clients={clients} />
        </div>
    );
};

Landing.propTypes = {
    heroHeadline: PropTypes.object.isRequired,
    vennDiagramVideos: PropTypes.array,
    aboutHeadline: PropTypes.object.isRequired,
    aboutVideoId: PropTypes.string.isRequired,
    aboutVideoPosterImage: PropTypes.object.isRequired,
    aboutVideoPosterVideos: PropTypes.array,
    workHeadline: PropTypes.object.isRequired,
    workCaseStudies: PropTypes.array,
    clients: PropTypes.array,
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
};

export default Landing;
