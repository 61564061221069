import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './PlayButton.module.scss';

const PlayButton = ({ className, ...props }) => {
    return (
        <div className={cx(styles.root, className)} {...props}>
            <svg
                className={styles.icon}
                width="130"
                height="91"
                viewBox="0 0 130 91"
                fill="none"
            >
                <rect
                    x="1"
                    y="1.66797"
                    width="128"
                    height="88"
                    stroke="white"
                    strokeWidth="2"
                />
                <path
                    className={styles.arrow}
                    d="M53.25 27.4144L84 45.168L53.25 62.9215L53.25 27.4144Z"
                    stroke="white"
                    strokeWidth="2"
                />
            </svg>
            <svg
                className={styles.iconHover}
                width="130"
                height="91"
                viewBox="0 0 130 91"
                fill="none"
            >
                <path
                    d="M86 45.168L52.25 64.6535L52.25 25.6824L86 45.168Z"
                    fill="#FA1682"
                />
            </svg>
        </div>
    );
};

PlayButton.propTypes = { className: PropTypes.string };

export default PlayButton;
