import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTransform, useSpring, motion } from 'framer-motion';
import { scaleVal } from 'utils';
import Img from 'components/ui/Img';
import styles from './PopcornItem.module.scss';

let popcornArr = [
    { src: '/images/landing/popcorn-1.webp', width: 150, height: 112 },
    { src: '/images/landing/popcorn-2.webp', width: 182, height: 129 },
    { src: '/images/landing/popcorn-3.webp', width: 167, height: 107 },
    { src: '/images/landing/popcorn-4.webp', width: 186, height: 134 },
    { src: '/images/landing/popcorn-5.webp', width: 180, height: 102 },
];

let zIndexArr = [-10, -10, 0, 2];

const PopcornItem = ({ offsetTop, scrollY, scaleFactor, isVideoPlaying }) => {
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    const [startPos, setStartPos] = useState(0);
    const [endPos, setEndPos] = useState(0);
    const [buffer, setBuffer] = useState(0);
    const [size, setSize] = useState(0);
    const [rotate, setRotate] = useState(0);
    const [mass, setMass] = useState(1);
    const [popcorn, setPopcorn] = useState({});
    const [zIndex, setZIndex] = useState(0);
    const [scaleSize, setScaleSize] = useState(0);
    const [scaleEndPos, setScaleEndPos] = useState(0);
    const [hideOnPlay, setHideOnPlay] = useState(1);

    useEffect(() => {
        setOffsetX(scaleVal(Math.random(), [0, 1], [0, 100]));
        setOffsetY(scaleVal(Math.random(), [0, 1], [100, 200]));
        setStartPos(scaleVal(Math.random(), [0, 1], [0, -200]));
        setEndPos(scaleVal(Math.random(), [0, 1], [800, 1400]));
        setBuffer(scaleVal(Math.random(), [0, 1], [500, 800]));
        setSize(scaleVal(Math.random(), [0, 1], [0.9, 1.8]));
        setRotate(scaleVal(Math.random(), [0, 1], [0, 360]));
        setMass(scaleVal(Math.random(), [0, 1], [0.1, 10]));
        setZIndex(zIndexArr[Math.floor(Math.random() * zIndexArr.length)]);
        setPopcorn(popcornArr[Math.floor(Math.random() * popcornArr.length)]);
    }, []);

    const clamp = (num, min, max) => {
        return num <= min ? min : num >= max ? max : num;
    };

    useEffect(() => {
        zIndex > 0 && isVideoPlaying ? setHideOnPlay(0.2) : setHideOnPlay(1);
    }, [isVideoPlaying, zIndex]);

    let clampScaleFactor = clamp(scaleFactor + 0.2, 0, 1);

    useEffect(() => {
        setScaleSize(scaleFactor * size);
        setScaleEndPos(clampScaleFactor * hideOnPlay * endPos);
    }, [buffer, clampScaleFactor, endPos, scaleFactor, hideOnPlay, size]);

    const springOptions = {
        damping: 20,
        stiffness: 20,
        mass: mass,
        bounce: 1.25,
    };

    // element position
    const y = useSpring(
        useTransform(
            scrollY,
            [offsetTop - buffer, offsetTop + buffer],
            [startPos + '%', scaleEndPos + '%']
        ),
        springOptions
    );

    const styleContainer = {
        left: offsetX + '%',
        bottom: offsetY + '%',
        zIndex: zIndex,
    };

    let scale = scaleSize;

    return (
        <div className={styles.root} style={styleContainer}>
            <motion.div
                className={styles.popcornBucket}
                initial={{ y: 0 }}
                style={{ y, scale, rotate }}
            >
                {popcorn?.src && (
                    <Img
                        src={popcorn.src}
                        alt="Popcorn kernel"
                        width={popcorn.width}
                        height={popcorn.height}
                        sizes={`${popcorn.width}px`}
                    />
                )}
            </motion.div>
        </div>
    );
};

PopcornItem.propTypes = {
    offsetTop: PropTypes.number,
    scrollY: PropTypes.object,
    scaleFactor: PropTypes.number,
    isVideoPlaying: PropTypes.bool,
};

PopcornItem.defaultProps = {
    isVideoPlaying: false,
};

export default PopcornItem;
