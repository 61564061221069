import React, { useRef, useState, useEffect } from 'react';
import { useScroll, useTransform, useSpring, motion } from 'framer-motion';
import Img from 'components/ui/Img';
import { useGlobalStateContext } from 'utils/context/globalContext';
import { scaleVal, breakpoints } from 'utils';
import PopcornItem from './PopcornItem';
import styles from './Popcorn.module.scss';

const Popcorn = () => {
    const figureRef = useRef(null);
    const [offsetTop, setOffsetTop] = useState(0);
    const [scaleFactor, setScaleFactor] = useState(0);
    const [scaleEndPos, setScaleEndPos] = useState(0);
    const { scrollY } = useScroll();

    const endPos = 90;
    const popcornLen = 30;

    const { isVideoPlaying } = useGlobalStateContext();

    const popcornItems = new Array(popcornLen).fill(0);

    const buffer = 500;
    const springOptions = {
        damping: 10,
        stiffness: 100,
        mass: 1,
    };

    useEffect(() => {
        if (!figureRef.current) return;

        const onResize = () => {
            setOffsetTop(figureRef?.current?.offsetTop);
            setScaleFactor(scaleVal(window.innerWidth, [400, 1440], [0.5, 1]));
        };

        onResize();
        window.addEventListener('resize', onResize);

        return () => {
            window.addEventListener('resize', onResize);
        };
    }, [figureRef]);

    useEffect(() => {
        setScaleEndPos(scaleFactor * endPos);
    }, [scaleFactor]);

    const y = useSpring(
        useTransform(
            scrollY,
            [offsetTop - buffer, offsetTop + buffer],
            ['0%', scaleEndPos + '%']
        ),
        springOptions
    );

    return (
        <figure ref={figureRef} className={styles.root} role="presentation">
            <motion.div
                className={styles.popcornBucket}
                initial={{ y: 0 }}
                style={{ y }}
            >
                <Img
                    src={'/images/landing/popcorn-bucket.webp'}
                    alt="Popcorn Bucket"
                    width={1500}
                    height={1800}
                    sizes={`(max-width: ${breakpoints.medium}px) 80vw, 50vw`}
                />
            </motion.div>
            {popcornItems.map((value, i) => (
                <div key={i} className={styles.popcornItem}>
                    <PopcornItem
                        offsetTop={offsetTop}
                        scrollY={scrollY}
                        scaleFactor={scaleFactor}
                        isVideoPlaying={isVideoPlaying}
                    />
                </div>
            ))}
        </figure>
    );
};

export default Popcorn;
