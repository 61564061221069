import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from 'components/ui/SectionHeader';
import Figure from 'components/ui/SectionHeader/Figure';
import CaseStudyGrid from 'components/ui/CaseStudyGrid';
import Button from 'components/ui/Button';
import styles from './Work.module.scss';

const LandingWork = ({ headline, caseStudies }) => {
    return (
        <section className={styles.root}>
            <SectionHeader headline={headline}>
                <Figure theme="coffee" />
            </SectionHeader>
            <CaseStudyGrid caseStudies={caseStudies} />
            <Button className={styles.seeMoreButton} href="/work">
                see more work
            </Button>
        </section>
    );
};

LandingWork.propTypes = {
    headline: PropTypes.object,
    caseStudies: PropTypes.array,
};

export default LandingWork;
