import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useInterval } from 'react-use';
import Img from 'components/ui/Img';
import styles from './HeroLogo.module.scss';

const LogoSvg = () => {
    return (
        <svg
            className={styles.logoSvg}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 190 47"
        >
            <path
                d="M26.876 33.971V8.507h-8.397l-4.99 16.758h-.068L8.362 8.507H0V33.97h5.988L5.78 14.666l5.506 19.305h4.301l5.438-19.133.068.07-.206 19.063h5.988zM48.797 26.16c.103-.516.138-.964.138-1.514 0-5.919-4.37-10.427-10.083-10.427-5.609 0-10.014 4.749-10.014 10.255 0 5.678 4.612 10.117 10.117 10.117 4.027 0 7.502-2.444 9.326-6.607h-5.85c-.93 1.204-1.927 1.79-3.476 1.79-2.34 0-4.163-1.446-4.473-3.614h14.315zm-14.246-3.854c.447-2.03 2.236-3.27 4.301-3.27 2.203 0 3.923 1.308 4.37 3.27h-8.671zM68.6 33.971L61.616 23.27l7.192-8.396h-6.779l-5.3 6.917h-.068V8.507h-5.713V33.97h5.713v-9.188h.069l5.402 9.188h6.47zM113.259 33.971V14.873h-5.713v2.03h-.069c-1.101-1.755-3.269-2.684-5.609-2.684-5.471 0-9.566 4.542-9.566 10.186 0 5.781 3.923 10.22 9.566 10.22 2.512 0 4.784-1.032 5.609-2.718h.069v2.064h5.713zm-10.496-4.68c-2.65 0-4.749-2.168-4.749-4.817 0-2.753 2.065-4.921 4.68-4.921a4.847 4.847 0 014.852 4.852c0 2.753-2.099 4.886-4.783 4.886zM133.737 33.971V21.79c0-5.644-3.648-7.571-7.089-7.571-2.305 0-3.992.723-5.196 2.684h-.069v-2.03h-5.334V33.97h5.713v-10.53c0-2.58.963-3.854 3.269-3.854 2.099 0 2.994 1.205 2.994 3.786V33.97h5.712zM142.128 12.843V8.507h-5.712v4.336h5.712zm0 21.128V14.873h-5.712V33.97h5.712zM158.616 20.241c-.276-3.75-3.201-6.022-7.33-6.022-3.854 0-7.399 2.443-7.399 6.229 0 2.236 1.274 4.129 6.091 5.609 2.857.894 3.51 1.445 3.51 2.512 0 .894-.998 1.48-1.996 1.48-1.135 0-1.995-.551-2.167-1.756h-5.678c.413 3.889 3.475 6.332 7.777 6.332 4.336 0 7.777-2.512 7.777-6.504 0-2.787-1.48-4.61-6.022-5.815-2.65-.688-3.579-1.205-3.579-2.237 0-.723.688-1.273 1.652-1.273.929 0 1.72.344 1.755 1.445h5.609zM190 33.971V22.168c0-5.334-2.822-7.949-7.089-7.949-2.615 0-4.68 1.239-6.056 3.407-1.205-2.168-3.029-3.407-5.506-3.407-2.443 0-4.267.895-5.265 2.684h-.069v-2.03h-5.334V33.97h5.713V23.682c0-2.65.997-4.095 3.234-4.095 1.962 0 2.856 1.377 2.856 3.854v10.53h5.713V23.407c0-2.512.998-3.82 3.166-3.82 1.961 0 2.925 1.101 2.925 4.095v10.29H190z"
                fill="currentColor"
            />
        </svg>
    );
};

const icons = [
    {
        className: 'collaborative',
        src: '/images/culture/collaborative.webp',
        dimensions: {
            width: 500,
            height: 800,
        },
    },
    {
        className: 'fearless',
        src: '/images/culture/fearless.webp',
        dimensions: {
            width: 500,
            height: 800,
        },
    },
    {
        className: 'inclusive',
        src: '/images/culture/inclusive.webp',
        dimensions: {
            width: 500,
            height: 800,
        },
    },
    {
        className: 'independent',
        src: '/images/culture/independent.webp',
        dimensions: {
            width: 500,
            height: 800,
        },
    },
    {
        className: 'optimistic',
        src: '/images/culture/optimistic.webp',
        dimensions: {
            width: 700,
            height: 700,
        },
    },
    {
        className: 'realistic',
        src: '/images/culture/realistic.webp',
        dimensions: {
            width: 500,
            height: 800,
        },
    },
    {
        className: 'weird',
        src: '/images/culture/weird.webp',
        dimensions: {
            width: 500,
            height: 800,
        },
    },
    {
        className: 'helmet',
        src: '/images/culture/helmet.webp',
        dimensions: {
            width: 700,
            height: 700,
        },
    },
];

const HeroLogo = ({ hasIntro }) => {
    const rootRef = useRef(null);

    const animDur = 1.4;
    const total = icons.length - 1;

    const [isAkimbo, setIsAkimbo] = useState(false);
    const [isInterval, setIsInterval] = useState(true);
    const [currentIcon, setCurrentIcon] = useState(-1);

    useInterval(
        () => {
            const nextItem = currentIcon + 1;

            setIsAkimbo(true);
            setCurrentIcon(currentIcon + 1);

            if (nextItem === total) {
                setIsInterval(false);
            }
        },
        isInterval ? animDur * 1000 : null
    );

    return (
        <div
            ref={rootRef}
            className={cx(styles.root, {
                [styles.isAkimbo]: isAkimbo,
            })}
        >
            <div className={styles.inner}>
                {hasIntro ? (
                    <h1 className={styles.headline}>
                        We are <span className="sr-only">Mekanism</span>
                    </h1>
                ) : (
                    <span className="sr-only">Mekanism</span>
                )}
                <div className={styles.mek} role="presentation">
                    <LogoSvg />
                </div>
                <div className={styles.anism} role="presentation">
                    <LogoSvg />
                </div>
                <div className={styles.svgDimension}>
                    <LogoSvg />
                    <div className={styles.icons}>
                        {icons.map((icon, i) => (
                            <div
                                key={i}
                                className={cx(
                                    styles.icon,
                                    styles[icon.className],
                                    {
                                        [styles.isActive]: currentIcon === i,
                                        [styles.lastIcon]: total === i,
                                    }
                                )}
                                style={{
                                    animationDuration: `${animDur}s`,
                                }}
                            >
                                {icon.src && (
                                    <Img
                                        src={icon.src}
                                        width={icon.dimensions.width}
                                        height={icon.dimensions.height}
                                        sizes={`20vw`}
                                        alt={icon.className}
                                        lazyFadeIn={false}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

HeroLogo.propTypes = {
    inView: PropTypes.bool,
    hasIntro: PropTypes.bool,
};

HeroLogo.defaultProps = {
    hasIntro: true,
};

export default HeroLogo;
