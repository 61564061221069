import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Player from '@vimeo/player';
import { useMedia } from 'react-use';
import { useIntersectionObserver, usePrevious } from '@madeinhaus/hooks';
import PlayButton from 'components/ui/PlayButton';
import {
    useGlobalDispatchContext,
    SET_IS_VIDEO_PLAYING,
} from 'utils/context/globalContext';
import Poster from './Poster';
import styles from './VimeoPlayer.module.scss';

const VimeoPlayer = ({ id, poster, isSlide }) => {
    const vimeoRef = useRef(null);

    const [isPlaying, setIsPlaying] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const hasHoverQuery = useMedia('(hover: hover)', false);

    const [inView, ref, el] = useIntersectionObserver();

    const prevIsInView = usePrevious(inView);

    const dispatch = useGlobalDispatchContext();

    useEffect(() => {
        const createPlayer = () => {
            vimeoRef.current = new Player(el.current, {
                id,
                byline: false,
                portrait: false,
                title: false,
                responsive: true,
                playsinline: false,
            });
            vimeoRef.current.on('ended', playerEndedHandler);
            isSlide && vimeoRef.current.on('pause', playerEndedHandler);
        };

        if (prevIsInView !== inView) {
            if (inView) {
                createPlayer();
            } else {
                vimeoRef.current
                    ?.pause()
                    .then(function () {
                        setIsPlaying(false);
                    })
                    .catch(function (error) {
                        console.log(error.name);
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView, prevIsInView, id, isSlide]);

    useEffect(() => {
        return () => {
            vimeoRef.current?.off('ended', playerEndedHandler);
            vimeoRef.current?.off('pause', playerEndedHandler);
            vimeoRef.current?.destroy();
        };
    }, []);

    useEffect(() => {
        isPlaying
            ? dispatch({ type: SET_IS_VIDEO_PLAYING, isVideoPlaying: true })
            : dispatch({
                  type: SET_IS_VIDEO_PLAYING,
                  isVideoPlaying: false,
              });
    }, [dispatch, isPlaying]);

    const playerEndedHandler = () => setIsPlaying(false);

    const clickPosterHandler = () => {
        setIsPlaying(true);
        vimeoRef.current?.play();
    };

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    return (
        <div
            className={cx(styles.root, isPlaying && styles.isPlaying, {
                [styles.isSlide]: isSlide,
                [styles.isHovering]: isHovering,
            })}
        >
            <div className={styles.inner}>
                <div ref={ref} className={styles.player} />
                <div className={styles.posterContainer}>
                    <Poster {...poster} />
                </div>
                <PlayButton
                    className={styles.playButton}
                    onMouseEnter={hasHoverQuery ? handleMouseEnter : null}
                    onMouseLeave={hasHoverQuery ? handleMouseLeave : null}
                    onClick={clickPosterHandler}
                />
            </div>
        </div>
    );
};

VimeoPlayer.propTypes = {
    id: PropTypes.string.isRequired,
    poster: PropTypes.object.isRequired,
    isSlide: PropTypes.bool,
};

VimeoPlayer.defaultProps = {
    isSlide: false,
};

export default VimeoPlayer;
