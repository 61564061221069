import React from 'react';
import PropTypes from 'prop-types';

const HeroVennAnimation = ({ videos }) => {
    return (
        <video width="100%" playsInline muted autoPlay loop>
            {videos.map((v, i) => (
                <source key={i} src={v.url} type={v.contentType} />
            ))}
        </video>
    );
};

HeroVennAnimation.propTypes = { videos: PropTypes.array };

export default HeroVennAnimation;
