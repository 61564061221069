import React from 'react';
import PropTypes from 'prop-types';
import { BLOCKS } from '@contentful/rich-text-types';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';
import { getWeekDay } from 'utils';
import HeroLogo from './HeroLogo';
import HeroVennAnimation from './HeroVennAnimation';
import styles from './Hero.module.scss';

const Hero = ({ headline, vennDiagram }) => {
    return (
        <section className={styles.root}>
            <div className={styles.logoContainer}>
                <div className={styles.logo}>
                    <HeroLogo />
                </div>
            </div>
            <div className={styles.textContainer}>
                <div className={styles.textInner}>
                    <Text
                        className={styles.greetings}
                        as="span"
                        theme="label"
                    >{`Hello, happy ${getWeekDay()}`}</Text>
                </div>
                <div className={styles.textInner}>
                    <RichText
                        richText={headline}
                        overrides={{
                            renderNode: {
                                [BLOCKS.HEADING_2]: (node, children) => (
                                    <Text
                                        className={styles.headline}
                                        as="h2"
                                        theme="sectionHeadlineHero"
                                    >
                                        {children}
                                    </Text>
                                ),
                            },
                        }}
                    />
                </div>
            </div>
            <div className={styles.vennContainer}>
                <HeroVennAnimation videos={vennDiagram} />
            </div>
        </section>
    );
};

Hero.propTypes = {
    headline: PropTypes.object,
    vennDiagram: PropTypes.array,
};

export default Hero;
